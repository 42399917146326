.nav {
  padding: 16px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11%;
}
.nav_bottom_border {
  height: 10px;
  background-image: linear-gradient(
    139deg,
    rgb(251, 136, 23),
    rgb(255, 75, 1),
    rgb(193, 33, 39),
    rgb(224, 42, 255)
  );
}
/* .nav > .nav-header > .nav-title {
  display: inline-block;
  font-size: 22px;
  color: #000;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  font-weight: 500;
} */

.nav > .nav-btn {
  display: none;
}
.nav > .nav-links {
  display: inline;
  float: right;
  font-size: 18px;
  align-items: center;
}
.nav > .nav-links > a {
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  position: relative;
  margin: 13px;
  padding-bottom: 10px;
  cursor: pointer;
  transition: 0.5s transform ease;
}
.nav > .nav-links > a::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #f21111;
  transition: 0.5s transform ease;
  transform: scale3d(0, 1, 1);
  transform-origin: 0 50%;
}
.nav > .nav-links > a:hover::before {
  transform: scale3d(1, 1, 1);
}
.nav > #nav-check {
  display: none;
}

/*  */
#basic-menu a {
  color: #fff;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  font-size: 14px;
  text-transform: capitalize !important;
}

.nav-title img {
  width: 18%;
  margin-left: 33%;
}

@media (max-width: 600px) {
  .dropdown a {
    color: #fff;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 27px;
    top: 27px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
  }
  .nav > .nav-btn > label > span:hover,
  .nav #nav-check:checked ~ .nav-btn > label > span {
    border-top: 2px solid rgb(168, 14, 14) !important;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #f21111;
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #333;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 88px;
    left: 0px;
    text-align: center;
    z-index: 999;
  }
  .nav > .nav-links > a {
    display: block;
    margin-left: 18px;
    color: white;
  }
  .nav > .nav-links > a:first-child {
    margin-top: 30px;
  }
  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }

  .nav > .nav-header {
    display: inline;
  }
  /* .nav > .nav-header > .nav-title {
    padding-left: 0;
  } */
  .nav {
    padding: 17px;
    justify-content: space-between;
  }
  /* .navbar-logo {
    display: none;
  } */
  .home-subnav {
    display: none;
  }

  .searchBook {
    width: 89%;
  }
  .search-bar-small {
    width: 100% !important;
    display: flex;
    justify-content: center;
    background-color: aqua;
  }
  .search-bar {
    display: none;
  }
  .nav_bottom_border {
    height: 7px;
  }
  #basic-menu {
    margin-top: 35px !important;
    margin-left: -80px !important;
  }
}

.nav-title img{
  margin-left: 5%;
}

.header_text {
  font-size: calc(0.9em + 0.9vmin);
  font-weight: 500;

  margin: 0;

  --x-offset: -0.0625em;
  --y-offset: 0.0625em;
  --stroke: 0.025em;
  --background-color: white;
  --stroke-color: lightblue;

  text-shadow: var(--x-offset) var(--y-offset) 0px var(--background-color),
    calc(var(--x-offset) - var(--stroke)) calc(var(--y-offset) + var(--stroke))
      0px var(--stroke-color);
}
/* for browsers that support spread, added in
https://drafts.csswg.org/css-text-decor-4/#text-shadow-property
currently: none browsers! none of them!
I can't even find a test: 
https://wpt.fyi/results/css/css-text-decor */
@supports (text-shadow: 1px 1px 1px 1px black) {
  h1 {
    text-shadow: var(--x-offset) var(--y-offset) 0px 0px var(--background-color),
      var(--x-offset) var(--y-offset) var(--stroke) 0px var(--stroke-color);
  }
}
