.footer {
  border-top: 5px solid #c52d2f;
  padding-top: 50px;
  padding-bottom: 30px;
  color: #fff;
  background: url(../../assets/pattern-fabric-4k-blue-wallpaper-preview.jpg);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}
.footer_container {
  display: flex;
  justify-content: space-between;
  width: 51%;
}
.footer_container ul {
  padding: 0;
  list-style: none;
}
.footer_container li {
  font-size: 14px;
  margin-bottom: 9px;
}
.footer_container h6 {
  margin-top: 0;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 400;
}
.footer_container p {
  font-size: 14px;
  margin: 0;
  line-height: 1.4;
}
.footer_link li {
  cursor: pointer;
}
.footer_link a {
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}
.footer_bootom {
  background-color: black;
  padding: 10px 0 10px 0;
  color: rgb(158, 155, 155);
  display: flex;
  justify-content: center;
}
.footer_inner_container {
  width: 51%;
  display: flex;
  justify-content: space-between;
}
.footer_inner_container a {
  color: rgb(158, 155, 155);
  text-decoration: none;
}
.footer_inner_container span {
  font-size: 14px;
}
.span_highlight:hover {
  color: red;
  transition: 0.1s;
  cursor: pointer;
}
.span_name_highlight {
  color: red !important;
}

/* For small devices (596px and up) */
@media only screen and (max-width: 600px) {
  .footer_container {
    flex-direction: column;
    width: 100%;
    padding: 0 30px 0 30px;
  }
  .footer_heading_gap {
    margin-top: 50px !important;
  }
  .footer_inner_container {
    width: 100%;
    padding: 0 30px 0 30px;
    justify-content: center;
    flex-direction: column;
  }
  .footer_inner_container :last-child {
    margin-top: 6px;
  }
}

/* For laptpo (1550px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .footer_container {
    width: 60%;
  }
}
