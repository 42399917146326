.girls_collection {
  width: 55%;
  margin-top: 80px;
  margin-bottom: 40px;
}
.girls_collection img {
  width: 100%;
  cursor: pointer;
  filter: none;
  -webkit-filter: grayscale(0);
  transition: 0.2s;
}

.girls_collection img:hover {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

/* For small devices (596px and up) */

@media only screen and (max-width: 600px) {
  .girls_collection {
    width: 100%;
    margin-top: 0;
    padding: 50px 40px 0 40px;
  }
}

/* For laptpo (1550px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .girls_collection {
    width: 65%;
  }
}
