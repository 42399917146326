.contact_location {
  width: 55.5%;
  margin-top: 80px;
  margin-bottom: 40px;
}
.contact_location h4 {
  font-size: 1.9em;
  line-height: 1.5em;
  padding: 0 0 25px 0;
  color: #bf0d6c;
  margin: 0;
  font-weight: 300;
}
.contact_location p {
  line-height: 1.9em;
  margin: 0;
}

/* For small devices (596px and up) */
@media only screen and (max-width: 600px) {
  .contact_location {
    width: 100%;
    margin-top: 0;
    padding: 40px 40px 0 40px;
  }
}

/* For laptpo (1550px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .contact_location {
    width: 65%;
  }
}
