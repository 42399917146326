.gallery{
    display: flex;
    margin-top:7rem;
    margin-bottom: 40px;
    gap: 16px;
}
  .card_container {
    cursor: pointer !important;
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .card_container:hover .overlay {
    opacity: 1;
    transform: scale(1);
  }
  .overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    transform: scale(1.3);
    opacity: 0;
    transition: transform 0.4s ease 0s;
  }
  .overlay span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 4em;
    color: #fff;
    font-family: Sans-serif;
  }
  .card_container img {
    transition: transform 0.8s ease;
    display: block;
  }
  .card_container:hover img {
    transform: scale(1.1);
  }
  
    /* For small devices (596px and up) */
 @media only screen and (max-width: 600px) {
    .gallery{
       flex-direction: column;
       width: 100% ;
       padding:0 20px 0 20px !important; 
       margin-top:4rem;
       gap: 16px;
    } 
    .card_container img {
        width: 100%;
      }
       
    }