.contact_form {
  margin-top: 50px;
}
.contact_form input {
  width: 100%;
  border: 1px solid #a8a8a8;
  outline: none;
  padding: 10px;
  font-size: 14px;
  color: #555;
}
.contact_form textarea {
  border: 1px solid #a8a8a8;
  width: 100%;
  height: 200px;
  outline: none;
  font-size: 14px;
  color: #555;
  resize: none;
  padding: 10px;
  margin-bottom: 27px;
  font-family: inherit;
  line-height: inherit;
}
.send_button {
  font-size: 1em !important;
  background: #cd3486;
  padding: 0.7em 2em;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  cursor: pointer;
  width: 10% !important;
}

.send_button:hover {
  background: #88085d;
}

/* For small devices (596px and up) */

@media only screen and (max-width: 600px) {
  .contact_form input {
    width: 100%;
  }
  .contact_form textarea {
    width: 100%;
  }
  .send_button{
    width: 100% !important;
}
}
