.our_serviceses {
  width: 55%;
  margin-bottom: 40px;
  margin-top: 80px;
}
.our_serviceses h2 {
  font-size: 1.9em;
  line-height: 1.5em;
  margin: 8px !important;
  font-weight: 400;
}
.our_serviceses p {
  line-height: 1.9em;
  margin: 0;
}
.service_title {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}
.title_sep {
  width: 16%;
  height: 6px;
  border-top: 1px solid #e0dede;
  border-bottom: 1px solid #e0dede;
}

/* araw style */

.indicator {
  position: relative;
  width: 50px;
  height: 50px;
  transform: rotate(-45deg);
}
.indicator span {
  position: absolute;
  left: 0;
  top: 0;
  width: 30%;
  height: 30%;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  animation: animate 1s linear infinite;
}
.indicator span:nth-child(1) {
  top: -30px;
  left: -30px;
  animation-delay: 0s;
}
.indicator span:nth-child(2) {
  top: -15px;
  left: -15px;
  animation-delay: 0.2s;
}
.indicator span:nth-child(3) {
  top: 0;
  left: 0;
  animation-delay: 0.4s;
}
.indicator span:nth-child(4) {
  top: 15px;
  left: 15px;
  animation-delay: 0.6s;
}
.indicator span:nth-child(5) {
  top: 30px;
  left: 30px;
  animation-delay: 0.8s;
}
@keyframes animate {
  0% {
    border-color: rgb(1, 22, 255);
    transform: translate(0, 0);
  }
  20% {
    border-color: rgb(33, 126, 193);
    transform: translate(15px, 15px);
  }
  20.1%,
  100% {
    border-color: rgb(214, 19, 25);
  }
}

/* For small devices (596px and up) */

@media only screen and (max-width: 600px) {
  .our_serviceses {
    width: 100%;
    padding: 40px;
    margin-top: 40px;
  }
  .indicator {
    display: none;
  }
  .title_sep {
    width: 20%;
    height: 6px;
    border-top: 1px solid #680404;
    border-bottom: 1px solid #5b0404;
  }
}

/* For laptpo (1550px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .our_serviceses {
    width: 65%;
  }
}
