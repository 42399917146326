.women_collection {
  width: 55%;
  margin-top: 80px;
  margin-bottom: 40px;
}
.women_collection img {
  width: 100%;
}
.modal-content {
  width: 27% !important;
}
.modal {
  background-color: rgb(0 0 0 / 84%) !important;
}
.slide img {
  filter: none !important;
  -webkit-filter: none !important;
}

/* For small devices (596px and up) */

@media only screen and (max-width: 600px) {
  .women_collection {
    width: 100%;
    margin-top: 0;
    padding: 50px 40px 0 40px;
  }
  .modal-content {
    width: 100% !important;
  }
  .previous,
  .next {
    font-size: 45px !important;
    font-weight: normal !important;
  }
}

/* For laptpo (1550px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .women_collection {
    width: 65%;
  }
}
